import { useState } from "react";
import "./App.css";

function Buttons({counter, setCounter}) {
  return (
    <>
      <button onClick={() => setCounter(counter + 1)}>
        Increase by 1
      </button>
      <button onClick={() => setCounter(counter - 1)}>
        Decrease by 1
      </button>
    </>
  );
}

export default function MyApp() {
  const [counter, setCounter] = useState(0)
  return (
    <div className="test">
      <h1>Counter</h1>
      <label>Current amount: {counter}</label><br/>
      <Buttons counter={counter} setCounter={setCounter}/>
    </div>
  );
}